
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { WidgetEnum } from '@/models/enums/WidgetEnum';
import { FilterTimeAxisSpanEnum } from '@/models/enums/FilterTimeAxisSpanEnum';
import WidgetDefinition, { ParamValues } from '@/models/Charts/widgetDefinition';

@Component({
  components: {
    WidgetContainer: () => import('../../components/Charts/widgetContainer/WidgetContainer.vue'),
  },
})
export default class DetailedTopSalespeople extends Vue {
  @Prop()
  private selectedTimeFilter!: [string, string];

  @Prop()
  private selectedDateGrouping!: FilterTimeAxisSpanEnum;

  @Prop()
  private paramValues!: ParamValues;

  @Prop({ required: false })
  private onClose?: () => void;

  private isModalOpen = true;

  private get firstRowWidgetDefinitions(): WidgetDefinition[] {
    return [
      WidgetEnum.AmountVsNumberOfQuotesBySalesperson,
      WidgetEnum.SalesAmountBySalesperson,
      WidgetEnum.SalesItemChangesBySalesperson,
      WidgetEnum.NumberOfSalesBySalesperson,
    ].map(
      (widgetEnumValue) =>
        new WidgetDefinition(
          widgetEnumValue,
          [],
          this.selectedTimeFilter,
          [],
          undefined,
          this.selectedDateGrouping,
          undefined,
          this.paramValues,
        ),
    );
  }

  private get salesPerSalespersonTimelineWidgetDefinition(): WidgetDefinition {
    return new WidgetDefinition(
      WidgetEnum.SalesPerSalespersonTimeline,
      [],
      this.selectedTimeFilter,
      [],
      undefined,
      this.selectedDateGrouping,
      undefined,
      this.paramValues,
    );
  }

  @Watch('isModalOpen')
  private onModalOpenChanged() {
    if (!this.isModalOpen) {
      this.onClose?.();
    }
  }
}
